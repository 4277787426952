<template>
    <div class="container">
        <div v-if="!isMobile" class="pcWrapper">
            <div class="relationsWrapper">
                <div class="bannerWrapper">
                    <img src="https://img.i2soft.net/i2official-web/assets/images/dynamic/dyn_back.png"/>

                    <div class="bannerTextWrapper">
                      <span class="bannerText1">行业格局已经发生巨变</span>
                      <span class="bannerText2">我们为您提供场景化的</span>
                      <span class="bannerText3">深度行业解决方案</span>
                    </div>
                </div>
                <div class="mapWrapper">
                    <div style="width: 80%;position: relative;margin: 0 auto">
                        <div class="annoce_box">
                            <div class="inner-tit center gray">
                                <h3 class="h3">公司动态</h3>
                            </div>
                            <div
                                    class="annonce_border"
                                    v-for="dyn in dynamicList"
                                    :key="dyn.id"
                            >
                                <a :href="dyn.url" target="_blank" class="All">
                                    <a  :href="dyn.url" class="left_box">
                                        <div class="date_box">
                                            <span class="num">{{dyn.num}}</span>
                                        </div>
                                    </a>
                                    <div class="content_box">
                                        <span> </span>
                                    </div>
                                    <div class="right_box">
                                        <div class="cell_box">
                                            <div class="cell">
                                                <h3 class="h3">{{ dyn.content }}</h3>
                                                <div class="info">
                                                    <span>{{ dyn.url }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isMobile} from "../../utils/index";

    export default {
        data() {
            return {
                isMobile: false,
                keyWords: '',
                dynamicList:[],
            };
        },
        created() {
            this.getData();
        },
        mounted() {
            this.isMobile = isMobile();
            window.addEventListener("scroll", this.getScroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.getScroll);
        },
        methods: {
            //获取数据并处理
            getData(){
                this.$axios.get("/parameter/dynamicList").then((res)=>{
                    this.dynamicList= res.data.data;
                    console.log("dynamicList",this.dynamicList)
                    })
            },
            // 切换tab
            handleClick(tab, event) {
                console.log(tab, event);
            },
            getScroll() {
                // this.indexNumTop = $("#index_num").offset().top;
                this.scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
                ) {
                    //设置滚动大于300时的执行内容
                    // console.log(
                    //   "document.documentElement.scrollTop=",
                    //   document.documentElement.scrollTop
                    // );
                    this.$store.commit("TO_AfterScroll");
                } else {
                    this.$store.commit("TO_AfterScroll_false");
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    * {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .relationsWrapper {
        background-color: #ffffff;

        .bannerWrapper {
            width: 100%;
            position: relative;

            img {
                width: 100%;
                height: auto;
            }

            .bannerTextWrapper {
                position: absolute;
                top: 20%;
                left: 65%;
                display: flex;
                flex-direction: column;

                .bannerText1 {
                    color: rgba(255, 255, 255, 100);
                    font-size: 35px;
                    text-align: left;
                    font-family: PingFangSC-Light;
                }

                .bannerText2 {
                    color: rgba(255, 255, 255, 100);
                    font-size: 40px;
                    text-align: left;
                    font-family: PingFangSC-Medium;
                }

                .bannerText3 {
                    color: rgba(255, 255, 255, 100);
                    font-size: 25px;
                    text-align: left;
                    font-family: PingFangSC-Light;
                }
            }
        }

        .announcementWrapper {
            .top_box {
                background-color: rgba(246, 246, 246, 1);
                position: relative;
                padding: 1px 0px;
                height: 50px;
                top: -3px;
            }
        }

        .mapWrapper {
            display: flex;
            flex-direction: column;
            margin: auto;
            padding: 0px;
            /*公司动态*/
            .annoce_box {
                margin-bottom: 20px;
                margin-top: 20px;
                width: 100%;
                height: 100%;
                .center {
                    text-align: center;
                    h3 {
                        color: #333333;
                        font-size: 26px;
                    }
                }
                .annonce_border {
                    background-color: rgba(242, 243, 242, 1);
                    margin-top: 10px;
                }

                .annonce_border .All {
                    display: flex;
                    width: 100%;;
                    text-decoration: none;
                    color: #333;
                    margin: 0;
                    padding: 0;
                }

                .All:link{
                    a{
                        color: rgba(37, 94, 162, 100);
                    }
                }
                .All:visited{
                    a{
                        color: rgba(37, 94, 162, 100);
                    }
                }
                .All:hover {
                    a{
                        content: '';
                        color: #FFF;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(53, 135, 195, 1);
                    }
                }
                .left_box {
                    width: 120px;
                    height: 120px;
                    padding: 20px;
                    position: relative;
                    .date_box{
                        margin-top: 10px;
                        margin-left: 10px;
                        .num{
                            font-size: 40px;
                            margin-bottom: 10px;
                            font-family: 'ziticqtezhanti';
                        }
                    }
                }

                .right_box {
                    width: calc(100% - 122px);
                    margin-left: 2px;
                    position: relative;
                    padding: 0 0 0 3.125vw;
                }

                .left_box i {
                    font-style: normal;
                    font-size: 40px;
                    line-height: 1;
                    font-family: 'ziticqtezhanti';
                    display: block;
                    position: relative;
                    transition: color .3s ease;
                }

                .left_box span {
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    bottom: 20px;
                    font-size: 18px;
                    font-family: 'NeutraTextLight';
                    transition: color .3s ease;
                }

                .content_box {
                    background-color: #FFF;
                    width: 0.5vw;
                }
                .cell_box {
                    height: 100%;
                    position: relative;
                    text-align: left;
                    margin-top: 1.5%;
                    display: flex;
                }

                .cell_box .cell {
                    width: 75%;
                    display: inline-block;
                    vertical-align: middle;
                }

                .h3 {
                    font-size: 15px;
                    transition: color .3s ease;
                }

                .info {
                    font-size: 80%;
                    margin-bottom: 15px;
                    color: rgba(37,125, 159, 100);
                }

                .cell_box .cell span {
                    margin-right: 20px;
                }

                .pdf_img {
                    width: 20%;
                    height: 100%;
                    position: relative;
                    margin-right: 3%;
                    text-align: right;
                }

                .pdf_img img {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

</style>
